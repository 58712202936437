import React, {useEffect, useRef, useState} from 'react';
import Typography from '@material-ui/core/Typography';
import { WeeklyRunnersInput,} from '@queensland-running/qr-components';
import {RegisterWeeklyCompetitionRunner, Runner} from '@generated/schema';
import {StepNavigator, StepProps} from '../../event-registration-flow';
import {useCompetitionDetailsQuery} from '../../queries.generated';
import {useWeeklyRegistrationContext} from "../../../../../contexts";
import clubSchool from '@data/club_school.json'
import {useMediaQuery} from "@material-ui/core";

interface RegisterForEventProps {
  competitionId: string
}

type RunnerWithId = RegisterWeeklyCompetitionRunner & { id: string, index: number; isEditMode: boolean };
const useRegisterForEvent = ({competitionId}: RegisterForEventProps) => {
  const {data: eventData, loading} = useCompetitionDetailsQuery({
    variables: {
      id: competitionId,
    }
  });

  return {
    runners: (eventData?.runners?.data! as Runner[]) ?? [],
    competition: eventData?.competition,
    userAccount: eventData?.userAccount,
    loading,
  };
};
export const WeeklyRegistration = ({handleNext, handleSkip, handleBack, competitionId}: StepProps) => {
  const {runnerDetails, updateRunners, isRegistrationReady } = useWeeklyRegistrationContext();
  const {competition} = useRegisterForEvent({competitionId});
  // const [temporaryRunners, setTemporaryRunners] = useState<RunnerWithId[]>(runnerDetails);

  // const isEditing = !!temporaryRunners.find(({isEditMode}) => isEditMode) ?? false;

  const onSubmit = () => {
    // updateRunners(temporaryRunners);

    handleNext && handleNext()
  }

  const m = useMediaQuery("(max-width: 960px)")
  return (
    <>
      <Typography variant="h5" color="primary">
        Who is running?
      </Typography>
      <WeeklyRunnersInput
        isMobile={m}
        label="Runners"
        name="runners"
        errors={''}
        value={runnerDetails}
        onChange={updateRunners}
        onSave={updateRunners}
        eventDate={competition?.day}
        lookupData={clubSchool}
      />
      <br/>
      <StepNavigator
        handleBack={handleBack}
        backText={'Back'}
        handleNext={onSubmit}
        nextText={'Next'}
        nextDisabled={!isRegistrationReady ?? true}
      />
    </>
  );
};
