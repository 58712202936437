import {Chip, Typography} from '@material-ui/core';
import React from 'react';
import {Contact, usePrograms} from '@components';
import {Competition} from '@generated/schema';
import {mapRoute, RouteKeys, Routes} from '@constants';
import {
    Blockquote,
    Button,
    ButtonWrapper,
    Calendar as CalendarComponent,
    ErrorComponent,
    LoadingComponent,
    PageTitle,
    UnstyledA,
    UnstyledLink,
} from '@queensland-running/qr-components';
import {AppConfig, useConfig} from '@constants/config';
import {Section} from '@components/section/section';
import {useCalendar} from '@components/higher-order/calendar';
import {CompetitionType} from 'types';

const renderAction = (
  props: Pick<Competition, 'id' | 'day' | 'agenda'> & { results?: string, __typename: string, status?: string } & // | 'results' | 'status' | 'registrations'
    Pick<AppConfig, 'toggles'>,
) => {
  const { id, day, toggles, results, __typename, status } = props;
  //parkKey, results, status, registrations,
  const isHoliday = __typename === 'NoCompetition';

  const eventDate = new Date(day);
  const inTheFuture = eventDate > new Date();
  const numWeeks = toggles.eventRegistrationWeeks as number;

  let futureCutoff = new Date();
  futureCutoff.setDate(futureCutoff.getDate() + numWeeks * 7);

  const wayInTheFuture = eventDate > futureCutoff;

  if (isHoliday) return null;
  if (status === 'CANCELLED') {

      return (
      <Chip label={status.replace(/_/g, ' ')} disabled color="secondary" size="medium" style={{ width: '100%' }} />
    );
  }

  if (wayInTheFuture) return null;

  if (results) {
    return (
      <UnstyledA href={results} target="_blank" rel="noopener noreferrer">
        <Button color={'secondary'} variant={'contained'} fullWidth title='View Results'/>
      </UnstyledA>
    );
  }

  if (inTheFuture) {
    if (toggles.registration || toggles.familyRegistration || toggles.googleForm ) {
      // if (registrations && registrations.data.length > 0) {
      //   return (
      //     <UnstyledLink to={`${Routes[RouteKeys.event]}?eventId=${id}`}>
      //       <Button color="primary" variant="outlined" fullWidth title='Details'/>
      //     </UnstyledLink>
      //   );
      // }
      return (
        <UnstyledLink to={`${Routes[RouteKeys.event]}?competitionId=${id}`}>
          <Button color="primary" variant="contained" fullWidth title='Register'/>
        </UnstyledLink>
      );
    } else {
      if (status && status !== "ACTIVE") {
        return (
          <Chip label={status.replace(/_/g, ' ')} disabled color="primary" size="medium" style={{ width: '100%' }} />
        );
      }
      return null;
    }
  }
};

const showAdditionalYearlyInfo = (year: string) => {
  const information: {[key: string]: JSX.Element | null} = {
    '2025': (
      <Blockquote color="secondary">
        <b>Event registration will open from March 1.</b>
        <br/>
        <br/>

        <p>
          Our March race days will commence at 3.00 p.m. in order to take advantage of the cooler weather.<br/><br/>
          April back to the 2.00 p.m. starts.<br/><br/><br/>We have planned a Schools Prep Meet for the 5 April. Details will be available soon.
        </p>
        <br/>
        <br/>
        The program is to be confirmed
      </Blockquote>
    ),
    '2020': (
      null
      /*<Blockquote color="secondary">
        <p>
          As per Government advice, the 2020 Queensland Running Cross Country Season is in recess. We will recommence
          when advised by the Government that it is appropriate to resume.
          <br />
          <br />
          As a result, registration is currently unavailable. Please check back here soon, or keep an eye on our
          Facebook page for updates.
          <br />
          <br />
          <a href="https://www.facebook.com/queenslandrunning/" target="_blank" rel="noopener noreferrer">
            Queensland Running Facebook Page
          </a>
        </p>
      </Blockquote>*/
    ),

    '2021': (
      <Blockquote color="secondary">
        <Section verticalSpacing>
          <Typography variant="h3">Update: 17th July 2021 - Winter Championships</Typography>
          <Typography>
            This year due to the uncertainty with regards to health issues we have decided to make a change with regards
            to the Winter Championships on 24 July.
          </Typography>
          <Typography>
            We are running our normal weekly program commencing at 2.00 p.m. and each age group championship race will
            be included throughout the afternoon. As usual, all runners may participate in each race but for example in
            the 500m race starting at 2.00 p.m. the 4, 5 and 6 year boys and girls will be the only runners eligible for
            a medal.
          </Typography>
          <Typography>
            On the 31st July we will have additional championship races for our Open and Masters runners.
          </Typography>
        </Section>
        <ButtonWrapper>
          <UnstyledLink to={`${Routes[RouteKeys.event]}?eventId=2021-WC`}>
            <Button color="primary" variant="contained" title='View more details'/>
          </UnstyledLink>
          <UnstyledA href={'/assets/pdfs/results/Results 2021 WC.pdf'} target="_blank" rel="noopener noreferrer">
            <Button color={'secondary'} variant={'contained'} title='2021 Winter Championship Results'/>
          </UnstyledA>
        </ButtonWrapper>
      </Blockquote>
    ),
  };

  return information[year] ?? null;
};

const EventsView = () => {
  const { toggles, constants: { currentYear: appYear } } = useConfig();
  const { getProgram } = usePrograms()
  const { calendar, loading, error, currentYear, setYear, availableYears, calendarV2 } = useCalendar();

  const onHandleYearChange = ({ target: { value } }: React.ChangeEvent<{ value: unknown }>) => {
    setYear((value as string) ?? appYear);
  };

  if (loading) {
    return <LoadingComponent />;
  }

  if (error) {
    return <ErrorComponent />;
  }

  const { events } = calendar;
  const v2CalendarEvents = calendarV2?.events;

const useV2 = toggles.v2;
  return (
    <>
      <PageTitle title={`Calendar`} />

      {showAdditionalYearlyInfo(currentYear)}

      <Typography gutterBottom>
        Competition is held most Saturday afternoons (except Easter) from March. Various venues are used around Brisbane for events ranging from 500m to 8000m.
      </Typography>
      <Typography gutterBottom>
        All events are timed and results are usually available below by the following Tuesday.
      </Typography>
      <Typography gutterBottom>
        A Fun and Fitness Walk is also held each competition afternoon. This walk covers the course for the shortest
        event - 500m (Program 1) or 800m (Program 2). Walk, hop, skip or jump your way around the course. Bring your dog
        or even read a book. <em>Please note: Fun and Fitness Walk is not timed.</em>
      </Typography>
      <br />

      {error && <div>Unable to load component</div>}

      <CalendarComponent
        onChangeYear={onHandleYearChange}
        availableYears={availableYears}
        selectedYear={currentYear}
        competitions={
          useV2 ? v2CalendarEvents : events.map((competition) => ({
              ...competition,
              //@ts-ignore
              programItems: getProgram(competition.program || competition.agenda)
          })) as CompetitionType[]
        }
        onClickVenueRouter={(venue: string) => mapRoute(venue)}
        action={renderAction}
        toggles={toggles}
      />

      <br />
      {/*<Typography>*/}
      {/*  The last day of competition includes the 1km, 3km and 4km open championships. These are open championships with*/}
      {/*  categories for masters men and women, the masters age groups are the same as the winter championships. Only open*/}
      {/*  and masters athletes can enter the 1km and 4km championships, however any athlete can enter the 3km championship*/}
      {/*  race as an open runner. Race fees are payable and are the same as listed for the winter championships.*/}
      {/*</Typography>*/}
      {/*<br />*/}
      <Typography>
        Please email <Contact name="siselin" type="emailAddress" /> to report any errors / omissions in the weekly
        results.
      </Typography>
    </>
  );
};

export const Calendar = React.memo(EventsView);
