import React from 'react';
import { Typography} from '@material-ui/core';
import {Button, PageTitle, UnstyledLink} from '@queensland-running/qr-components';
import {Routes} from '@constants';
import {useConfig} from '@constants/config';
import {useTranslation} from "react-i18next";

const EventsView = () => {
    const {t} = useTranslation('common')
    return (
        <>
            <PageTitle title={t('app.routes.events')}/>
            <Typography variant="h2" color="primary" gutterBottom>{t('events.header.weekly')}</Typography>

            <Typography gutterBottom>
                Competition is held on Saturday afternoons (except Easter) from March. Various venues are used around Brisbane for events ranging from 500m to 8000m.</Typography>
            <Typography gutterBottom>
                All events are timed and results are usually available by the following Tuesday.
            </Typography>
            <Typography gutterBottom>
                A Fun and Fitness Walk is also held each competition afternoon. This walk covers the course for the
                shortest
                event - 500m (Program 1) or 800m (Program 2). Walk, hop, skip or jump your way around the course. Bring
                your dog
                or even read a book. <em>Please note: Fun and Fitness Walk is not timed.</em>
            </Typography>

            <br/>
            <UnstyledLink to={Routes.calendar.replace(':year', '2021')}>
                <Button color="secondary" variant="contained" title={t('app.routes.calendar')}/>
            </UnstyledLink>
            <br/>
            <br/>
            <br/>
            {/*<Typography variant="h2" color="primary" gutterBottom>{t('events.header.specialEvents')}</Typography>*/}

            {/*<Typography gutterBottom>*/}
            {/*    Queensland Running holds special events throughout the season as outlined below.*/}
            {/*</Typography>*/}
            {/*<Typography gutterBottom>*/}
            {/*    All special events are not restricted to registered athletes or those who compete regularly with*/}
            {/*    Queensland*/}
            {/*    Running during the season.*/}
            {/*</Typography>*/}
            {/*<Typography gutterBottom>*/}
            {/*    Queensland Running always welcomes entries to our major events from the community at large i.e schools,*/}
            {/*    sporting groups, clubs, individuals, businesses etc.*/}
            {/*</Typography>*/}
            {/*<br/>*/}
            {/*<br/>*/}
            {/*<Typography variant="h3" color="primary" gutterBottom>*/}
            {/*    {t('events.header.rockettRelays')}*/}
            {/*</Typography>*/}
            {/*<Typography>*/}
            {/*    The Rockett One and Two Hour Relay allows athletes to compete in teams to determine who can cover the*/}
            {/*    greatest*/}
            {/*    distance in the one or two hour time period. It is open to all athletes.*/}
            {/*</Typography>*/}
            {/*<br/>*/}
            {/*{toggles.rockettRelays ? (*/}
            {/*    <UnstyledLink to={Routes.rockettRelays}>*/}
            {/*        <Button color="secondary" variant="contained" title={t('events.category.RR')}/>*/}
            {/*    </UnstyledLink>*/}
            {/*) : (*/}
            {/*    <Button color="secondary" variant="contained" disabled title={t('events.category.RR')}/>*/}
            {/*)}*/}
            {/*<br/>*/}
            {/*<br/>*/}
            {/*<Typography variant="h3" color="primary" gutterBottom>{t('events.header.winterChampionship')}</Typography>*/}
            {/*<Typography>*/}
            {/*    The Queensland Running season includes a Winter Championship. It is open to all athletes, with distances*/}
            {/*    allocated to athletes aged 4 years to 16 years and a range of distances from 500m to 6000m available for*/}
            {/*    Open*/}
            {/*    and Masters athletes in specified aged groups.*/}
            {/*</Typography>*/}
            {/*<br/>*/}
            {/*{toggles.winterChampionships ? (*/}
            {/*    <UnstyledLink to={Routes.winterChampionships}>*/}
            {/*        <Button color="secondary" variant="contained" title={t('events.category.WC')}/>*/}
            {/*    </UnstyledLink>*/}
            {/*) : (*/}
            {/*    <Button color="secondary" variant="contained" disabled title={t('events.category.WC')}/>*/}
            {/*)}*/}
            {/*<br/>*/}
        </>
    );
};

export const Events = React.memo(EventsView);
