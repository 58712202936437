import React, {useState} from 'react';
import {compose} from 'recompose';
import queryString from 'query-string';
import {Box, Grid, Tab, Tabs, Typography} from '@material-ui/core';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {FetchCompetitionQuery, useFetchCompetitionQuery} from './queries.generated';

import {mapRoute, RouteKeys} from '@constants/routes';
import styled from 'styled-components';
import {ProgramItem,} from '@generated/schema';
import fallbackImg from '@images/fallback/parks.jpg';
import {
    ErrorComponent,
    EventDate,
    EventProgram,
    EventVenue,
    LoadingComponent,
    PageTitle,
    ProgramTable,
    Results,
    VenueCard,
} from '@queensland-running/qr-components';
import {toRoutes} from '@utils/mapper';
import {usePrograms, withErrorBoundary} from '@components/higher-order';
import EventRegistrationFlow from "../competition-registration/event-registration-flow";
import {useTranslation} from "react-i18next";
import {EventDetails} from "./event-details";
import {CompetitionForms} from "./event-forms";
import useRegister from "./useRegister";
import {SingleRegistration} from "../competition-registration/single-registration-flow";

function TabPanel(props: any) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
          {value === index && <Box style={{ padding: 3 }}>{children}</Box>}
      </div>
    );
}

const SideGrid = styled(Grid)(({theme}) => ({
    '&&&': {
        [theme.breakpoints.only('sm')]: {
            '> .MuiGrid-item': {
                padding: '12px',
            },
            margin: '-12px',
        },
        [theme.breakpoints.up('xs')]: {
            height: 'fit-content',
            '> .MuiGrid-item:not(:last-child)': {
                marginBottom: '1rem',
            },
        },
    },
}));


type QueryParams = {
    eventId?: string;
    competitionId?: string;
};

const enhance = compose(withErrorBoundary, withRouter, React.memo);

const EventRegistration = ({competition}: FetchCompetitionQuery) => {
    const { onSubmit } = useRegister();

    return <SingleRegistration onSubmit={onSubmit} details={() => {
        return null
    }} competition={competition} />;
}

const RegisterView = ({location: {search}, history}: RouteComponentProps) => {
    const {t} = useTranslation('common')
    const { getProgram } = usePrograms()
    const [registrationTab, setRegistrationTab] = useState<number>(0)
    const competitionId = (queryString.parse(search) as QueryParams).competitionId;
    const {data, loading} = useFetchCompetitionQuery({
        errorPolicy: 'all',
        variables: {
            id: competitionId!,
        },
        fetchPolicy: 'network-only',
    });
    if (!competitionId) {
        return null;
    }
    if (loading) {
        return <LoadingComponent/>;
    }
    const handleChangeRegistrationTab = (event: React.SyntheticEvent, newValue: number) => {
        setRegistrationTab(newValue);
    };

    //@ts-ignore
    const { competition } = data;

    if (competition) {
        if (competition.__typename === 'NoCompetition') {
            history.replace({
                pathname: RouteKeys.events,
            });
        }
        const programItems = getProgram(competition?.program || competition?.agenda) as ProgramItem[]

        return (
            <>
                <PageTitle
                    title={t('app.routes.competitionDetails', {replace: {competition: competition.agenda ?? 'Competition Details'}})}
                    breadcrumbs={toRoutes([RouteKeys.calendar])}
                />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={8}>
                                <EventDate day={competition.day} variant="subtitle1"/>
                                <EventVenue venue={competition.venue || {displayName: 'TBC'}} variant="subtitle1"
                                            parentPath={'/venues/'}/>
                                <EventProgram program={competition.program} variant={'subtitle1'}/>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <CompetitionForms agenda={competition.__typename} />
                                <br/>
                                <Results competition={competition}/>
                            </Grid>
                        </Grid>

                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={8}>
                                {programItems && programItems.length > 0 && (
                                  <>
                                      <Typography variant="h5" color={'secondary'}>
                                          {competition.program}
                                      </Typography>
                                      <ProgramTable programItems={programItems}/>
                                  </>
                                )}
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                {competition.venue && (
                                  <VenueCard
                                    {...competition.venue}
                                    withLink
                                    href={mapRoute(competition.venue.url)}
                                    imageURL={competition.venue.imageURL || fallbackImg}
                                    showMap={competition.program === "Program 1" || competition.program === "Program 2"}
                                  />
                                )}
                            </Grid>
                        </Grid>

                        <br/>

                        <EventDetails agenda={competition.agenda}/>
                        <Box style={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={registrationTab} onChange={handleChangeRegistrationTab as any} aria-label="basic tabs example">
                                <Tab label="Registration" />
                                <Tab label="Google Form (old method)" />
                            </Tabs>
                        </Box>
                        <TabPanel value={registrationTab} index={0}>
                            <EventRegistrationFlow competitionId={competitionId}
                                                   competitionType={competition.__typename}
                                                   competitionDay={competition.day}
                            />
                        </TabPanel>
                        <TabPanel value={registrationTab} index={1}>
                            <iframe
                              src="https://docs.google.com/forms/d/e/1FAIpQLSfg39UZFO-NHJlKr3lg8kEza9IU1GjLcy7HYlnIv1INiodkMg/viewform?embedded=true"
                              // src="https://docs.google.com/forms/d/e/1FAIpQLSezofo5hAF5xuBK2abxiGIjzJ1h_RLBknffywXX6IAkmxIOFw/viewform?embedded=true"
                              width="640" height="1204" frameBorder="0" marginHeight={0}
                              marginWidth={0}>Loading…
                            </iframe>
                        </TabPanel>
                        <br/>
                    </Grid>
                    {/*<SideGrid container item xs={12} md={4} spacing={3}>*/}

                    {/*</SideGrid>*/}
                </Grid>
            </>
        );
    }

    return <ErrorComponent title={'We were unable to show this event at this time.'}/>;
};

// @ts-ignore
export const Event = enhance(RegisterView);
