export default {
    "app": {
        "title": "Queensland Running",
        "routes": {
            "calendar": "Calendar",
            "competitionDetails": "{{competition}}",
            "programs": "Programs",
            "volunteer": "Volunteer",
            "results": "Results",
            "venues": "Venues",
            "aboutUs": "About Us",
            "specialEvents": "Special Events",
            "events": "Events",
            "courseSignage": "Course Signage",
            "registration": "Registration"
        },
    },
    "account": {
        "buttons":{
            "signIn": "Sign In"
        }
    },
    "events": {
        category: {
            "WE": "Weekly Event",
            "RR": "Rockett Relays",
            "WC": "Winter Championship",
            "ASC": "All Schools Championship"
        },
        header: {
            "weekly": "Weekly Events",
            "specialEvents": "Special Events",
            "rockettRelays": "Rockett One and Two Hour Relay",
            "winterChampionship": "Winter Championship"
        }
    },
    "competition": {
        "buttons": {
            "conditionsOfEntry": "Conditions of Entry",
            "register": "Register",
            "registrationDetails": "View registration and payment options"
        }
    },
    "aboutUs": {
        "content": [
            {text: 'QUEENSLAND RUNNING is a community-based not-for-profit cross country concept developed to offer low cost opportunities for runners of all ages to participate regularly in a friendly, supportive and safe off-road environment.'},
            {text: 'Participation in Queensland Running is unrestricted. Anyone wishing to be involved, either in cross country competition or as a volunteer in our weekly events may do so. No experience is necessary.'},
            {text: 'Operating since 1999 as a metropolitan Brisbane competition, Queensland Running also caters for runners from the Sunshine Coast, Gold Coast, Wide Bay and Darling Downs regions.'},
            {text: 'Apart from the standard rules that apply in actual cross-country participation, we have three simple requests:'},
            {
                list: ['The observation at all times of the rules of good sportsmanship;',
                    'The wearing of appropriate attire (including upper body cover for men); and',
                    'All runners please wear their Queensland Running number.']
            },
            {text: 'The secret of Queensland Running\'s success lies in its simplicity. The condensed format of its regular winter competition, covers distances from 500 metres to 8000 metres in which runners of all ages can compete side-by-side at their own pace over distances of their choosing. For a modest entry fee, they are welcome to enter in more than one event at no additional cost. All activities are tailored to runners\' needs.'},
            {text: 'The \'Fun and Fitness Walk\' provides an ideal opportunity for parents to participate and also contribute to their 10,000 steps for the day!'},
            {text: 'The cross country season commences in March. This time frame reflects our strong belief that active youngsters need ample time to achieve the race conditioning and preparation required to run the distances prescribed for their ages at School, District, Regional and State levels.'}
        ]
    },
    "courseSignage": {
        "content": [
            {
                imageSrc: './assets/images/course-signage/DSCI1183.jpg',
                text: 'Athletes run between red and white flags, keeping the red flag to the left and the white flag to the right.'
            },
            {
                imageSrc: './assets/images/course-signage/DSCI1184.jpg',
                text: 'Relatively straight sections of the course are marked with sets of these red and white flags.'
            },
            {
                imageSrc: './assets/images/course-signage/DSCI1185.jpg',
                text: 'Where there is a significant change of direction, a yellow flag is placed next to either the red or white flag.'
            },
            {
                imageSrc: './assets/images/course-signage/DSCI1186.jpg',
                text: 'When passing through the set of flags, the runner turns on the same side on which the yellow flag is placed (left in the example) and seeks out the next red/white set of flags.'
            },
            {
                imageSrc: './assets/images/course-signage/DSCI1187.jpg',
                text: 'The yellow/red together indicates to the runner to turn left.'
            },
            {
                imageSrc: './assets/images/course-signage/DSCI1188.jpg',
                text: 'The yellow/white together indicates to the runner to turn right.'
            },
            {
                imageSrc: './assets/images/course-signage/DSCI1189.jpg',
                text: 'Large arrows can also be used to indicate the direction of a turn.'
            },
            {
                imageSrc: './assets/images/course-signage/DSCI1190.jpg',
                text: 'Signboards are another aid and are used at course junctions.'
            },
            {
                imageSrc: './assets/images/course-signage/DSCI1191.jpg',
                text: 'These signboards show the direction of the main course, and a minor loop (eg. 500m).'
            },
            {
                imageSrc: './assets/images/course-signage/DSCI1192.jpg',
                text: 'Runners should walk the course before the event to familiarise themselves with the signage used on that course.'
            },
            {
                imageSrc: './assets/images/course-signage/DSCI1193.jpg',
                text: 'The onus is on the runner to navigate the course in the chosen event.'
            },
            {
                imageSrc: './assets/images/course-signage/DSCI1194.jpg',
                list: ['In summary:', 'Keep red flags on the left, white flags on the right', 'Turn at yellow flags - yellow/red turn left, yellow/white turn right', 'Follow arrows and other direction signage']
            },
        ]
    },
    "venues": {
        "content": [
            "Queensland Running holds events at a variety of venues on Brisbane's northside and southside. We are grateful for the assistance provided by the Brisbane City Council and Moreton Bay Regional Council in making these venues available for our weekly events.",
            "We encourage family, friends and other supporters to submit information, photos and reviews of the courses, to add to that already provided on each of the venue pages.",
            "Please email {{contact(qr,email)}} with any suggestions"
        ]
    },
    "programs": {
        "program": [
            {
                "name": "Program 1",
                "program": [
                    {"time": "2:00 pm", "race": "500m"},
                    {"time": "2:15 pm", "race": "1500m"},
                    {"time": "2:35 pm", "race": "3000m, 5000m"},
                    {"time": "3:00 pm", "race": "4000m, 6000m & 8000m"},
                    {"time": "3:05 pm", "race": "Fun and Fitness Walk - 500m"},
                    {"time": "3:35 pm", "race": "2000m"},
                    {"time": "3:50 pm", "race": "1000m"}
                ]
            },
            {
                "name": "Program 2",
                "program": [
                    {"time": "2:00 pm", "race": "800m"},
                    {"time": "2:15 pm", "race": "1500m"},
                    {"time": "2:35 pm", "race": "3000m, 5000m"},
                    {"time": "3:00 pm", "race": "4000m, 6000m & 8000m"},
                    {"time": "3:05 pm", "race": "Fun and Fitness Walk - 800m"},
                    {"time": "3:35 pm", "race": "2000m"},
                    {"time": "3:50 pm", "race": "1000m"}
                ]
            },
            {
                "name": "Program 1^",
                "description": "Note: This program begins an hour later (at 3:00pm)",
                "program": [
                    {"time": "3:00 pm", "race": "500m"},
                    {"time": "3:15 pm", "race": "1500m"},
                    {"time": "3:35 pm", "race": "3000m, 5000m"},
                    {"time": "4:00 pm", "race": "4000m, 6000m & 8000m"},
                    {"time": "4:05 pm", "race": "Fun and Fitness Walk - 500m"},
                    {"time": "4:35 pm", "race": "2000m"},
                    {"time": "4:50 pm", "race": "1000m"}
                ]
            },
            {
                "name": "Program 2^",
                "description": "Note: This program begins an hour later (at 3:00pm)",
                "program": [
                    {"time": "3:00 pm", "race": "800m"},
                    {"time": "3:15 pm", "race": "1500m"},
                    {"time": "3:35 pm", "race": "3000m, 5000m"},
                    {"time": "4:00 pm", "race": "4000m, 6000m & 8000m"},
                    {"time": "4:05 pm", "race": "Fun and Fitness Walk - 800m"},
                    {"time": "4:35 pm", "race": "2000m"},
                    {"time": "4:50 pm", "race": "1000m"}
                ]
            },
            {
                "name": "Rockett Relays",
                "program": [
                    {time: '1:00 pm', race: '1 Hour Relay', ageGroup: ['10 Years and Under', '14 Years and Under']},
                    {time: '1:10 pm', race: '500m', ageGroup: ['Any']},
                    {time: '1:20 pm', race: '2000m', ageGroup: ['Any']},
                    {time: '1:30 pm', race: '2 Hour Relay', ageGroup: ['Open Masters (40+ years)']},
                    {time: '2:15 pm', race: '1000m', ageGroup: ['Any']}
                ]
            },
            {
                name: 'Winter Championships',
                program: [
                    {time: '1:00 pm', ageGroup: ['4, 5, 6 Years Boys & Girls'], race: '500m'},
                    {time: '1:15 pm', ageGroup: ['9, 10 Years Boys & Girls'], race: '2000m'},
                    {time: '1:35 pm', ageGroup: ['7, 8 Years Boys & Girls'], race: '1000m'},
                    {time: '1:50 pm', ageGroup: ['Open / Masters Men & Women'], race: '2000m'},
                    {time: '2:15 pm', ageGroup: ['11, 12, 13 Years Boys & Girls'], race: '3000m'},
                    {time: '2:40 pm', ageGroup: ['14, 15, 16 Years Boys & Girls', '17 Years Girls'], race: '4000m'},
                    {
                        time: '3:10 pm',
                        ageGroup: ['7, 18 Years Boys & 18 Years Girls', 'Open / Masters Men & Women*'],
                        race: '6000m'
                    }
                ]
            },
            {
                name: "All Schools Championships",
                program: [
                    {
                        event: '1',
                        time: '10.30am',
                        race: '500m',
                        ageGroup: ['5 Years Girls'],
                        yearBorn: ['2014']
                    },
                    {
                        event: '2',
                        time: '10.40am',
                        race: '500m',
                        ageGroup: ['5 Years Boys'],
                        yearBorn: ['2014']
                    },
                    {
                        event: '3',
                        time: '10.50am',
                        race: '800m',
                        ageGroup: ['6 Years Girls'],
                        yearBorn: ['2013']
                    },
                    {
                        event: '4',
                        time: '11.00am',
                        race: '800m',
                        ageGroup: ['6 Years Boys'],
                        yearBorn: ['2013']
                    },
                    {
                        event: '5',
                        time: '11.10am',
                        race: '800m',
                        ageGroup: ['7 Years Girls'],
                        yearBorn: ['2012']
                    },
                    {
                        event: '6',
                        time: '11.20am',
                        race: '800m',
                        ageGroup: ['7 Years Boys'],
                        yearBorn: ['2012']
                    },
                    {
                        event: '7',
                        time: '11.35am',
                        race: '1000m',
                        ageGroup: ['8 Years Girls'],
                        yearBorn: ['2011']
                    },
                    {
                        event: '8',
                        time: '11.45am',
                        race: '1000m',
                        ageGroup: ['8 Years Boys'],
                        yearBorn: ['2011']
                    },
                    {
                        event: '9',
                        time: '11.55pm',
                        race: '1500m',
                        ageGroup: ['9 Years Girls'],
                        yearBorn: ['2010']
                    },
                    {
                        event: '10',
                        time: '12.10pm',
                        race: '1500m',
                        ageGroup: ['9 Years Boys'],
                        yearBorn: ['2010']
                    },
                    {
                        event: '11',
                        time: '12.20pm',
                        race: '2000m',
                        ageGroup: ['10 Years Girls'],
                        yearBorn: ['2009']
                    },
                    {
                        event: '12',
                        time: '12.35pm',
                        race: '2000m',
                        ageGroup: ['10 Years Boys'],
                        yearBorn: ['2009']
                    },
                    {
                        event: '13',
                        time: '12.50pm',
                        race: '3000m',
                        ageGroup: ['11 Years Girls'],
                        yearBorn: ['2008']
                    },
                    {
                        event: '14',
                        time: '1.05pm',
                        race: '3000m',
                        ageGroup: ['11 Years Boys'],
                        yearBorn: ['2008']
                    },
                    {
                        event: '15',
                        time: '1.25pm',
                        race: '3000m',
                        ageGroup: ['12 Years Girls'],
                        yearBorn: ['2007']
                    },
                    {
                        event: '16',
                        time: '1.40pm',
                        race: '3000m',
                        ageGroup: ['12 Years Boys'],
                        yearBorn: ['2007']
                    },
                    {
                        event: '17',
                        time: '1.55pm',
                        race: '3000m',
                        ageGroup: ['13 Years Girls'],
                        yearBorn: ['2006']
                    },
                    {
                        event: '18',
                        time: '2.10pm',
                        race: '3000m',
                        ageGroup: ['13 Years Boys'],
                        yearBorn: ['2006']
                    },
                    {
                        event: '19-20',
                        time: '2.25pm',
                        race: '6000m',
                        ageGroup: ['16 & 17 Years Boys'],
                        yearBorn: ['2002', '2003']
                    },
                    {
                        event: '21-22',
                        time: '2.45pm',
                        race: '6000m',
                        ageGroup: ['18 & 19 Years Girls'],
                        yearBorn: ['2000', '2001']
                    },
                    {
                        event: '23-24',
                        time: '2.45pm',
                        race: '8000m',
                        ageGroup: ['18 & 19 Years Boys'],
                        yearBorn: ['2000', '2001']
                    },
                    {
                        event: '25-26',
                        time: '3.00pm',
                        race: '4000m',
                        ageGroup: ['14 & 15 Years Girls'],
                        yearBorn: ['2004', '2005']
                    },
                    {
                        event: '27-28',
                        time: '3.15pm',
                        race: '4000m',
                        ageGroup: ['14 & 15 Years Boys'],
                        yearBorn: ['2004', '2005']
                    },
                    {
                        event: '29-30',
                        time: '3.15pm',
                        race: '4000m',
                        ageGroup: ['16 & 17 Years Girls'],
                        yearBorn: ['2002', '2003']
                    },
                    {
                        event: '31*',
                        time: '3.40pm',
                        race: '4000m',
                        ageGroup: ['Queensland Middle Distance'],
                        yearBorn: ['Open and Masters']
                    }
                ]
            }
        ]
    }
}

